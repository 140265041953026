<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">

            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="login">
                <card type="login">
                  <h3 slot="header" class="header text-center">Bruger login</h3>

                  <fg-input v-model="form.username" addon-left-icon="nc-icon nc-single-02"
                            placeholder="Brugernavn eller email..." required></fg-input>

                  <fg-input v-model="form.password" addon-left-icon="nc-icon nc-key-25" placeholder="Password"
                            type="password" required></fg-input>
                  <div v-if="form.showWrongPassword == true" class="alert alert-danger">{{form.wrongPassword}}</div>
                  <br>
                  <p-button native-type="submit" slot="footer" type="success" round block class="mb-3">Login</p-button>
                  <div class="row" slot="footer">
                    <div class="col-6" style="text-align:left">
                    
                    </div>
                    <div class="col-6" style="text-align:right">
                      <a href="#/ResetPassword">Glemt password</a>
                    </div>
                  </div>


                  <p slot="footer" style="text-align:left" class="mb-3">
                  </p>

                </card>
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Checkbox, Button} from 'src/components/UIComponents';
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import Communication from 'src/communication/Communication.vue';
 

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button,
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },

      async login() {
        var item = await Communication.login(this.form.username, this.form.password);
        
        if (item == false || item == null) {
          this.form.password = "";
          this.form.showWrongPassword = true;
        }
        else {
          this.form.showWrongPassword = false;
          this.$router.push('/')
        }
      }
    },
    data() {
      return {
        form: {
          username: '',
          password: '',
          wrongPassword: 'Password eller brugernavn er forkert',
          showWrongPassword: false
        },
        type: ['', 'info', 'success', 'warning', 'danger'],
        notifications: {
          topCenter: false
        },
        modals: {
          classic: false,
          notice: false,
          mini: false
        }
      }
    },
    mounted() {
      //if (location.protocol !== 'https:') {
      //  location.replace(`https:${location.href.substring(location.protocol.length)}`);
      //}
    }
    ,
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
