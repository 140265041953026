<template>
  <div class="user">
    <div class="photo">
      <img :src="logo" alt="user avatar" />
    </div>
    <div class="info">
      <a data-toggle="collapse" href="#/userPage">
        <span>
         {{Username}}
        </span>
      </a>
    </div>
  </div>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions'
  import Communication from 'src/communication/Communication.vue';

  export default {
    components: {
      CollapseTransition
    },
    props: {

    },
    data() {
      return {
        recieveRespons: {},
        isClosed: true,
        logo: 'img/demo.png',
        Username: 'Demo - User'
      }
    },
    async mounted() {
      await Communication.GetUserInformation().
        then(response => {
          this.recieveRespons = response;
          this.Username= response.currentUser.name
        })
        .catch(error => console.log(error));
    },
    methods: {
      toggleMenu() {
        this.isClosed = !this.isClosed
      }
    }
  }
</script>
<style scoped>
  .nav.nav-menu {
    margin-top: 0;
  }
</style>
