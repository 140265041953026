<template>
  <div class="card card-user">
    <div class="image">
      <img src="static/img/background/damir-bosnjak.jpg" alt="...">
    </div>
    <div class="card-body">
      <div class="author">
        <a href="#">
          <img class="avatar border-gray" src="img/demo.png" alt="..." style="background-color:white">
          <h5 class="title">{{userInformation.name}}</h5>
        </a>
      </div>
      <p class="description text-center">
        "Badges opnået"
        <div v-for="rows in userInformation.badges">
          <div v-if="rows.achieved==true" class="text-center" style="height: 100%">
            <img :src="rows.imageUrl" :alt="rows.imageAlt" :title="rows.imageAlt" class="float-left" />
          </div>
        </div>
      </p>
    </div>
    <div class="card-footer">
      <hr>
      <div class="button-container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-6 ml-auto">
            <h5>{{userInformation.createdDate}}
              <br>
              <small>Oprettet:</small>
            </h5>
          </div>
          <div class="col-lg-4 col-md-6 col-6 ml-auto mr-auto">
            <h5>{{userInformation.updatedDate}}
              <br>
              <small>Sidst online:</small>
            </h5>
          </div>
          <div class="col-lg-3 mr-auto">
            <h5>{{userInformation.teamNumber}}
              <br>
              <small>Hold nr:</small>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Communication from 'src/communication/Communication.vue';

  export default {
    data() {
      return {
        userInformation: {},        
      }
    },
    async mounted() {

      if (sessionStorage.accessToken == undefined) {
        this.$router.push('/Login')
      }

      await Communication.GetCommand('api/User/GetDetailsForUserStatus').
        then(response => {
          this.userInformation = response;
          if (response == null) {
            this.$router.push('/Login')
            return;
          }
        })
        .catch(error => {
          console.log(error);
          this.$router.push('/Login');
          }
          );
    }
  }

</script>
<style>
  @media (max-width: 1280px) {
      .card-user .card-footer h5{
          font-size:10px !important;
          margin-left:-10px;
          margin-right:-10px;
      }
  }

</style>
