<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <div class="card" style="background-color: white">
          <div class="card-header">
            <h3>Indstillinger</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-2">
                <label for="basic-url" class="form-label">Brugernavn:</label>
                <div class="input-group">
                  <input type="text" class="form-control" disabled="disabled" v-model="userData.userName" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <label for="basic-url" class="form-label">Navn:</label>
                <div class="input-group">
                  <input type="text" class="form-control" disabled="disabled" v-model="userData.name" />
                </div>
              </div>
              <div class="col-2">
                <label for="basic-url" class="form-label">Efternavn:</label>
                <div class="input-group">
                  <input type="text" class="form-control" disabled="disabled" v-model="userData.lastName" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <label for="basic-url" class="form-label">Email:</label>
                <div class="input-group">
                  <input type="text" class="form-control" v-model="userData.email" />
                </div>
              </div>
              <div class="col-2">
                <label for="basic-url" class="form-label">Telefon:</label>
                <div class="input-group">
                  <input type="text" class="form-control" v-model="userData.phone" />
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

</template>
<script>  
  import Communication from 'src/communication/Communication.vue';

  export default {

    components: {
      
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    methods: {
    },
    data() {
      return {        
        userData: {}
      }
    },

    async mounted() {
      var item = await Communication.GetCommand('api/User/GetUserInformation');
      console.log(item);
      this.userData = item.currentUser;
      
    },
  }
</script>
<style>
</style>
