<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="title">Edit Profile</h4>
      </div>
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-md-5">
              <fg-input type="text"
                        label="Brugernavn"
                        :disabled="true"
                        placeholder="Username"
                        v-model="user.userName">
              </fg-input>
            </div>
            <div class="col-md-7">

              <fg-input type="text"
                        label="Email"
                        placeholder="Email"
                        v-model="user.email">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <fg-input type="text"
                        label="Fornavn"
                        placeholder="First Name"
                        v-model="user.name">
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input type="text"
                        label="Efternavn"
                        placeholder="Last Name"
                        v-model="user.lastName">
              </fg-input>
            </div>
          </div>

          <!--<div class="row">
            <div class="col-12">
              <fg-input type="text"
                        label="Billede"
                        placeholder="Profil billede"
                        v-model="user.imageUrl">

              </fg-input>
              <button type="submit" class="btn btn-info btn-fill btn-wd" @click.prevent="findImage">
                Find og Opload billede
              </button>
            </div>
          </div>-->

          <div class="row">
            <div class="col-md-8">
              <fg-input type="text"
                        label="Addresse"
                        placeholder="Home Address"
                        v-model="user.street">
              </fg-input>
            </div>
            <div class="col-md-4">
              <fg-input type="text"
                        label="Telefon"
                        placeholder="Telefon"
                        v-model="user.phone">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <fg-input type="text"
                        label="Postnummer"
                        placeholder="ZipCode"
                        v-model="user.postalCode">
              </fg-input>
            </div>
            <div class="col-md-4">
              <fg-input type="text"
                        label="By"
                        placeholder="City"
                        v-model="user.city">
              </fg-input>
            </div>

            <div class="col-md-4">
              <fg-input type="text"
                        label="Land"
                        placeholder="Country"
                        v-model="user.country">
              </fg-input>
            </div>
          </div>

          <div class="text-center">
            <button type="submit" class="btn btn-info btn-fill btn-wd" @click.prevent="updateProfile">
              Opdater Profil
            </button>
          </div>
          <div class="clearfix"></div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h4 class="title">Opdater password</h4>
      </div>
      <div class="card-body">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form>
            <div class="row">
              <div class="col-md-4">
                <ValidationProvider name="password"
                                    rules="required"
                                    v-slot="{ passed, failed }">
                  <fg-input
                            :error="failed ? 'Gammelt password er påkrævet': null"
                            :hasSuccess="passed"
                            name="password"
                            type="password"
                            label="Password"
                            placeholder="password"
                            v-model="passwordUser.oldPassword">
                  </fg-input>
                  </ValidationProvider>
              </div>
              <div class="col-md-4">
                <ValidationProvider vid="confirmation"
                                    rules="required"
                                    v-slot="{ passed, failed }">
                  <fg-input type="password"
                            label="Nyt Password"
                            placeholder="New Password"
                            :error="failed ? 'Nyt password er påkrævet': null"
                            :hasSuccess="passed"
                            name="newPassword"
                            v-model="passwordUser.newPassword">
                  </fg-input>
                  </ValidationProvider>
              </div>
              <div class="col-md-4">
                <ValidationProvider rules="required|confirmed:confirmation"
                                    v-slot="{ passed, failed }">
                  <fg-input type="password"
                            label="Nyt Password igen"
                            placeholder="New Password"
                            :error="failed ? 'Password er ikke identiske': null"
                         :hasSuccess="passed"
                         name="confirm"
                            v-model="passwordUser.newPasswordCheck">
                  </fg-input>
                  </ValidationProvider>
              </div>
              <!--<div class="col-12" v-if="isPasswordsEqual==false">
        <label class="alert alert-danger" style="color:black">Passwords er ikke ens</label>
      </div>-->

            </div>

            <div class="text-center">
              <button type="submit" class="btn btn-info btn-fill btn-wd" @click.prevent="updatePassword">
                Opdater password
              </button>
            </div>
            <div class="clearfix"></div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>

</template>
<script>
  import Communication from 'src/communication/Communication.vue';
  import { Modal } from 'src/components/UIComponents'
  import NotificationTemplate from './NotificationTemplate'

  import { extend } from "vee-validate";
  import { required, email, confirmed } from "vee-validate/dist/rules";

  extend("email", email);
  extend("required", required);
  extend("confirmed", confirmed);


  export default {
    components: {
      Modal
    },
    async mounted() {
      await Communication.GetCommand('api/User/GetEditableDetailsForUser').
        then(response => {
          this.user = response;

          if (response == null) {
            this.$router.push('/Login')
            return;
          }
        })
        .catch(error => console.log(error));

    },
    data() {
      return {
        isPasswordsEqual: false,
        type: ['success'],
        notifications: {
          topCenter: false
        },
        modals: {
          classic: false,
          notice: false,
          mini: false
        },
        user: {},
        passwordUser: {
          oldPassword: '',
          newPassword: '',
          newPasswordCheck: ''
        }
      }
    },
    methods: {
      notifyVue(verticalAlign, horizontalAlign) {
        this.$notify(
          {
            component: NotificationTemplate,
            icon: 'nc-icon nc-check-2',
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: this.type[0]
          })
      },
      async updateProfile() {
        await Communication.PostCommand('api/User/UpdateUser', this.user).
          then(response => {
            this.user = response;

            this.notifyVue('top', 'right');

            if (response == null) {
              this.$router.push('/Login')
              return;
            }
          })
          .catch(error => console.log(error));

      },
      async updatePassword() {
        if (this.passwordUser.newPassword == this.passwordUser.newPasswordCheck) {
          await Communication.PostCommand('api/User/UpdatePassword', this.passwordUser).
            then(response => {
              if(response == true)
                this.notifyVue('top', 'right');


              if (response == null) {
                this.$router.push('/Login')
                return;
              }
            })
            .catch(error => console.log(error));
        }
        else {

        }
      }
    }
  }

</script>
<style>
</style>
