<template>
  <div>
    <navbar>
      <div class="navbar-wrapper">
        <a class="navbar-brand" href="#/overview">Velkommen til Thriving universe</a>
      </div>
      <div>
        <a v-if="IsAdmin" @click="Admin" href="#" class="TopBarLinks">
          <i slot="label" style="font-size:x-large" class="nc-icon nc-atom" title="Administration"></i>
        </a>
        <!--<a @click="Settings" href="#" class="TopBarLinks">
          <i slot="label" style="font-size:x-large" class="nc-icon nc-settings-gear-65" title="Settings"></i>
        </a>-->
        <a @click="SignOut" href="#" class="TopBarLinks">
          <i slot="label" style="font-size:x-large" class="nc-icon nc-user-run" title="Sign out"></i>
        </a>
      </div>

    </navbar>
  </div>
</template>
<script>
  import { Navbar } from 'src/components/UIComponents'
  import Communication from 'src/communication/Communication.vue';

  export default {
    components: {
      Navbar,
    },
    props: {
      IsAdmin: true,
      },
    methods: {
      async SignOut() {
        await Communication.SignOut()
          .then(() => {
            sessionStorage.accessToken = undefined;
            sessionStorage.refreshToken = undefined;
            this.$router.push('/Login')
          })
      },
      Settings() {
        this.$router.push('/Settings')
      },
      Admin() {
        this.$router.push('/Administrator')
      },
    },   
  }

</script>
<style>
  .TopBarLinks {
    margin-left: 20px;
  }
</style>
