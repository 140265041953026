<template>
  <div class="content">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6 d-flex align-items-stretch" v-for="stats in statsCards">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="stats.title"
                    :badge_bg_color="stats.badge_bg_color"
                    :badge_color="stats.badge_color"
                    :text_bg_color="stats.text_bg_color"
                    :text_color="stats.text_color"
                    :image="stats.image"
                    :description="stats.description">
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card" :style="{'background-color': exercise.backgroundColor}">
          <div class="card-header">
            <h3><span>{{exercise.mainTitle}} </span>- {{exercise.subTitle}}</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <div class="card" style="background-color: #E1B4BB ">
                  <div class="card-body text-center">
                    <div class="embed-responsive embed-responsive-16by9">
                      <video controls>
                        <source v-if="exercise.videoUrl" :src="exercise.videoUrl" type="video/mp4">
                      </video>
                    </div>
                  </div>
                </div>
                <div class="card" style="background-color: #E1B4BB">
                  <div class="card-body">
                    <span v-html="exercise.content"> </span>
                  </div>
                </div>
              </div>
              <div class="col-8">
                <div class="row">
                  <div class="card" style="background-color:white; width:98%; height:100%">
                    <vue-editor v-model="content"></vue-editor>                    
                  </div>
                </div>
                <div class="row">
                  <div class="col-5 text-center">
                    <p-button type="info" @click="SaveDraft">
                      <i slot="label" class="nc-icon nc-paper"></i>
                      Gem kladde
                    </p-button>
                  </div>
                  <div class="col-7 text-center">
                    <p-button type="success" @click="MarkAsCompleted">
                      <i slot="label" class="nc-icon nc-send"></i>
                      Marker som udfyldt
                    </p-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
  import Communication from 'src/communication/Communication.vue';

  import { VueEditor } from "vue2-editor";
  import { Modal } from 'src/components/UIComponents'
  import NotificationTemplate from './NotificationTemplate'

  export default {

    components: {
      StatsCard,
      VueEditor,
      Modal
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    methods: {
      async SaveDraft() {
        // `this` inside methods points to the current active instance
        var result = await Communication.SendExcersizeData(this.content, 1, this.params.moduleNumber, this.params.sessionNumber);
        this.notifyVue('top', 'right');
      },
      async MarkAsCompleted() {
        var result = await Communication.SendExcersizeData(this.content, 2, this.params.moduleNumber, this.params.sessionNumber);
        this.notifyVue('top', 'right');
      },
      notifyVue(verticalAlign, horizontalAlign) {

        this.$notify(
          {
            component: NotificationTemplate,
            icon: 'nc-icon nc-check-2',
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: this.type[0]
          })
      },
    },
    data() {
      return {
        type: ['success'],
        notifications: {
          topCenter: false
        },
        modals: {
          classic: false,
          notice: false,
          mini: false
        },
        params: {},
        statsCards: [
          {
            type: 'info',
            icon: 'nc-icon nc-favourite-28',
            image: 'img/badges/badge-alarm.png',
            title: '112 Knap',
            description: 'Hvis du er kørt fast og har brug for hjælp',
            badge_bg_color: 'white',
            bagde_color: 'red',
            text_bg_color: 'red',
            text_color: 'white'
          },
          {
            type: 'warning',
            icon: 'nc-icon nc-globe',
            title: '"Thriving Alumni"',
            image: 'img/badges/badge-alumni.png',
            description: 'Med din deltagelse er du en del af den fremtidige rejse sammen med de mange andre i Alumni Thriving',
            badge_bg_color: '#2A3443',
            bagde_color: '',
            text_bg_color: '#',
            text_color: '#'
          },
          {
            type: 'success',
            icon: 'nc-icon nc-money-coins',
            title: '"I want to Thrive"',
            image: './img/badges/badge-iwant.png',
            description: 'Tillykke med at du er i gang med et forløb om at få et arbejdsliv i Stortrivsel',
            badge_bg_color: '#2A3443',
            bagde_color: '',
            text_bg_color: '#',
            text_color: '#'

          },
          {
            type: 'danger',
            icon: 'nc-icon nc-vector',
            title: '"Pay it forward now"',
            image: 'img/badges/badge-trophy.png',
            description: 'Tak - en del af din betaling er resereveret til en samlet pulje der kommer alle til gode',
            badge_bg_color: '#2A3443',
            bagde_color: '',
            text_bg_color: '#',
            text_color: '#'

          }
        ],
        content: {},
        exercise: {},
      }
    },

    async mounted() {

      let paramsResult = {}
      try {
        paramsResult = JSON.parse(localStorage['PageData'])
        this.params = paramsResult;
        console.log(this.params);
      } catch (error) {
        // ignore
      }

      var item = await Communication.PostCommand("api/Exercise/GetExerciseContent", paramsResult);
      this.exercise = item;
      console.log(this.exercise);
      var result = await Communication.GetExerciseData(paramsResult);
      this.content = result.data;
    },
  }
</script>
<style>
</style>
