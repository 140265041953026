<template>
  <div>
    <div class="row">
      <div class="col-4 text-center">
        <p-button type="info" @click="FrontPage">
          <i slot="label" class="nc-icon nc-paper"></i>
          Forsiden
        </p-button>
      </div>
      <div class="col-4 text-center">
        <p-button type="info" @click="Sessions">
          <i slot="label" class="nc-icon nc-paper"></i>
          Sessioner
        </p-button>
      </div>
      <div class="col-4 text-center">
        <p-button type="info" @click="Talents">
          <i slot="label" class="nc-icon nc-paper"></i>
          Talenter
        </p-button>
      </div>
    </div>
    <div v-if="isActive == 1">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
            </div>
            <div class="card-body table-responsive">
              <el-table :data="tableDataFrontPage">
                <el-table-column label="Title" property="mainTitle"></el-table-column>
                <el-table-column label="Sub-Title" property="subTitle"></el-table-column>
                <el-table-column align="right"
                                 label="Aktiv">
                  <template slot-scope="props">
                    <p-switch :value="props.row.isActive" @input="(value) => handleSwitchValueWelcomePage(props.row, value)"></p-switch>
                  </template>
                </el-table-column>
                <el-table-column label="Opdateret af" property="updatedBy"></el-table-column>
                <el-table-column label="Opdateret" property="updatedDate"></el-table-column>
                <el-table-column class-name="action-buttons td-actions"
                                 align="right"
                                 label="Actions">
                  <template slot-scope="props">
                    <p-button type="success" size="sm" icon @click="handleEditWelcomePage(props.$index, props.row)">
                      <i class="fa fa-edit"></i>
                    </p-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <p-button type="success">
            <i slot="label" class="nc-icon nc-share-66"></i>
            <a href="#" @click="saveWelcomePage" style="color:white;"> Gem Velkomst side</a>
          </p-button>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Main Title </label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Welcome.mainTitle" />
          </div>
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Module number</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Welcome.moduleNumber" />
          </div>
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Session number</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Welcome.sessionNumber" />
          </div>
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Video Url</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Welcome.videoUrl" />
          </div>

        </div>
        <div class="col-6">
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Background Color</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Welcome.backgroundColor" />
          </div>
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Text Background Color</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Welcome.textBackgroundColor" />
          </div>
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Text Color</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Welcome.textColor" />
          </div>
        </div>
      </div>


      <div class="row">
        <div class="card" style="background-color:white; width:98%; height:100%">
          <vue-editor v-model="Welcome.content"></vue-editor>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card" style="background-color:wheat">
            <div class="card-header">
              <h3><span style="color:#cc8899">{{Welcome.mainTitle}} </span>- {{Welcome.subTitle}}</h3>
            </div>
            <div class="card-body">

              <div class="row">
                <div class="col-10">
                  <div class="card" :style="{'background-color': Welcome.backgroundColor}">
                    <div class="card-body">
                      <div class="row">
                        <div class="offset-1 col-lg-7 col-sm-5 d-flex align-items-stretch">
                          <video height="205" controls>
                            <source :src="Welcome.videoUrl" type="video/mp4">
                          </video>

                        </div>
                        <div class="col-lg-3 col-sm-3 d-flex align-items-stretch">
                          <div class="card-body">
                            <span v-html="Welcome.content"> </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isActive == 2">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
            </div>
            <div class="card-body table-responsive">
              <el-table :data="tableData">
                <el-table-column label="Main Title" property="mainTitle"></el-table-column>
                <el-table-column label="Sub Title" property="subTitle"></el-table-column>
                <el-table-column align="right"
                                 label="Aktiv">
                  <template slot-scope="props">
                    <p-switch :value="props.row.isActive" @input="(value) => handleSwitchValue(props.row, value)"></p-switch>
                  </template>
                </el-table-column>
                <el-table-column label="Opdateret af" property="updatedBy"></el-table-column>
                <el-table-column label="Opdateret" property="updatedDate"></el-table-column>
                <el-table-column class-name="action-buttons td-actions"
                                 align="right"
                                 label="Actions">
                  <template slot-scope="props">
                    <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                      <i class="fa fa-edit"></i>
                    </p-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <p-button type="success">
            <i slot="label" class="nc-icon nc-share-66"></i>
            <a href="#" @click="saveExercise" style="color:white;"> Gem Sessions data</a>
          </p-button>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Main Title </label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Session.mainTitle" />
          </div>
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Sub Title</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Session.subTitle" />
          </div>
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Task document</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Session.linkTask" />
          </div>
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Description document</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Session.linkDescription" />
          </div>
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Example document</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Session.linkExample" />
          </div>
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Video Url</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Session.videoUrl" />
          </div>

        </div>
        <div class="col-3">
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Layout Id (1 or 3)</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Session.layout" />
          </div>         
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Background Color</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Session.backgroundColor" />
          </div>
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Text Background Color</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Session.textBackgroundColor" />
          </div>
          </div>
        <div class="col-3">
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Text Color</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Session.textColor" />
          </div>
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Module number</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Session.moduleNumber" />
          </div>
          <div class="input-group">
            <label for="lblSessionMainTitle" class="input-group-text">Session number</label>
            <input id="lblSessionMainTitle" class="form-control" type="text" v-model="Session.sessionNumber" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card" :style="{'background-color': Session.backgroundColor}">
            <div class="card-header">
              <h3><span>{{Session.mainTitle}}</span>- {{Session.subTitle}}</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-4">
                  <div class="card" :style="{'background-color': Session.textBackgroundColor}">
                    <div class="card-body text-center">
                      <video height="205" controls>
                        <source :src="Session.videoUrl" type="video/mp4">
                      </video>
                    </div>
                  </div>
                  <div class="card" :style="{'background-color': Session.textBackgroundColor}">
                    <div class="card-body">
                      <span :style="{'color': Session.textColor}" v-html="Session.content"> </span>
                    </div>
                  </div>
                </div>
                <div class="col-8">
                  <div class="row">
                    <div class="card" style="background-color:white; width:98%; height:100%">
                      <vue-editor v-model="Session.content"></vue-editor>
                    </div>
                  </div>
                  <div class="row">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isActive == 3">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-10">
                  <fg-input>
                    <el-select class="select-success"
                               size="large"
                               placeholder="Vælg bruger"
                               v-model="selects.simple">
                      <el-option v-for="option in users"
                                 class="select-success"
                                 :value="option.userId"
                                 :label="option.firstName"
                                 :key="option.firstName">
                      </el-option>
                    </el-select>
                  </fg-input>

                </div>
                <div class="col-md-2">
                  <div class="text-center">
                    <button type="submit" class="btn btn-info btn-fill btn-wd" @click.prevent="LoadUserAndTalents">
                      Vælg bruger
                    </button>
                  </div>
                </div>
                <div class="card-body table-responsive">
                  <el-table :data="tableDataTalents">
                    <el-table-column label="Talent" property="name"></el-table-column>
                    <el-table-column label="Score" property="score">
                      <template slot-scope="props">
                        <input id="lblSessionMainTitle" class="form-control" type="text" v-model="tableDataTalents[props.$index].score" @blur="UpdateTalent(props.row)" />
                      </template>
                    </el-table-column>
                    <el-table-column label="Potentiale" property="potential">
                      <template slot-scope="props">
                        <input id="lblSessionMainTitle" class="form-control" type="text" v-model="tableDataTalents[props.$index].potential" @blur="UpdateTalent(props.row)" />
                      </template>
                    </el-table-column>
                    <el-table-column label="Order" property="order">
                      <template slot-scope="props">
                        <input id="lblSessionMainTitle" class="form-control" type="text" v-model="tableDataTalents[props.$index].order" @blur="UpdateTalent(props.row)" />
                      </template>
                    </el-table-column>

                  </el-table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Communication from 'src/communication/Communication.vue';
  import { Modal } from 'src/components/UIComponents'
  import NotificationTemplate from './NotificationTemplate'
  import { VueEditor } from "vue2-editor";
  import Vue from 'vue'
  import { Table, TableColumn, Select, Option } from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  Vue.use(Table)
  Vue.use(TableColumn)

  export default {
    components: {
      PSwitch,
      VueEditor,
      Modal,
      [Option.name]: Option,
      [Select.name]: Select,
    },
    data() {
      return {
        users: {},
        selects: {
          simple: '',
        },
        Content: 'hello!',
        isActive: 1,
        tableData: [],
        tableDataFrontPage: [],
        tableDataTalents: [],
        Session: {},
        Welcome: {},
        type: ['', 'info', 'success', 'warning', 'danger'],
        notifications: {
          topCenter: false
        },
        modals: {
          classic: false,
          notice: false,
          mini: false
        }
      };
    },
    async mounted() {
      var result = await Communication.GetCommand('api/Exercise/GetAllExercises');
      var result2 = await Communication.GetCommand('api/Exercise/GetAllWelcomePages');
      var result3 = await Communication.GetCommand('api/User/GetAllUsers');

      console.log(result3);
      this.users = result3;
      this.tableData = result;
      this.tableDataFrontPage = result2;
    },
    methods: {
      async handleSwitchValue(row, value) {
        row.isActive = value;
        row.status = '';
        console.log(row);
        await Communication.PostCommand('api/Exercise/SaveExerciseContent', row);
        
        this.notifyVue('top', 'right');
      },
      async handleSwitchValueWelcomePage(row, value) {
        row.isActive = value;
        row.status = '';
        await Communication.PostCommand('api/Exercise/SaveExerciseContent', row);
        this.notifyVue('top', 'right');
      },
      async saveWelcomePage() {

        console.log("Save Welcome");
        this.Welcome.status = '';
        await Communication.PostCommand('api/Exercise/SaveExerciseContent', this.Welcome);
        this.notifyVue('top', 'right');

      },
      handleEditWelcomePage(index, row) {
        this.Welcome = row;
      },
      async UpdateTalent(row) {
        console.log(row);
        var item = {
          userId : this.selects.simple,
          talent : row
        };

        console.log(item);
        var result = await Communication.PostCommand('api/User/UpdateTalent', item);
        this.notifyVue('top', 'right');
      },
      async saveExercise() {
        console.log("Save");
        this.Session.status = '';
        await Communication.PostCommand('api/Exercise/SaveExerciseContent', this.Session);

        this.notifyVue('top', 'right');
      },
      async LoadUserAndTalents() {
        var result = await Communication.GetCommand('api/User/Talents?userId=' + this.selects.simple);
        this.tableDataTalents = result;
        console.log(result);
      },
      handleEdit(index, row) {
        console.log(row);
        this.Session = row;
      },
      HandleSession() {
        console.log("Tryk");
      },
      FrontPage() {
        this.Content = "FrontPage";
        this.isActive = 1;
      },
      Sessions() {
        this.Content = "Sessions";
        this.isActive = 2;
      },
      Talents() {
        this.Content = "Talents";
        this.isActive = 3;
      },
      tableRowClassName({ row, rowIndex }) {
        if (rowIndex === 0) {
          return 'table-success'
        } else if (rowIndex === 2) {
          return 'table-info'
        } else if (rowIndex === 4) {
          return 'table-danger'
        } else if (rowIndex === 6) {
          return 'table-warning'
        }
        return ''
      },
      notifyVue(verticalAlign, horizontalAlign) {
        const color = Math.floor((Math.random() * 4) + 1)
        this.$notify(
          {
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: this.type[color]
          })
      }

    }
  }

</script>
<style>
  .extended-forms .el-select {
    width: 100%;
  }
</style>
