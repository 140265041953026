<template>
  <div class="card card-stats" style="width:100%">
    <div class="row no-gutters" style="height:100%">
      <div class="col-3 " :style="{'background-color': badge_bg_color}">
        <slot name="header">
          <div class="text-center">
            <img :src="image" style="margin-top: 15px;" />
          </div>
        </slot>
      </div>
      <div class="col-9" :style="{'background-color': text_bg_color, 'padding-left': '5px'}">
        <div class="card-body">          
          <div class="row">
            <div class="col-12">
              <h1 :style="{'color': text_color, 'text-align':'center', 'margin-bottom':'10px', 'font-weight':'bold'}">{{title}}</h1>              
              <p :style="{'color': text_color, 'text-align':'center'}">{{description}} <br />
                <a v-if="title=='112 Knap'" href="mailto:jill@thriving-now.com" style="color:white; font-weight:bold">Klik her</a>
              </p>              
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>  
  export default {
    name: 'stats-card',
    props: {
      type: {
        type: String,
        description: 'Card text type (primary|info|danger|default|warning|success)'
      },
      title: {
        type: String,
        description: 'Card title'
      },
      description: {
        type: String,
        description: 'Description of Badge'
      },
      icon: {
        type: String,
        description: 'Card icon'
      },
      image: {
        type: String,
        description: 'Lars Folmer-Petersen'
      },
      badge_bg_color:
      {
        default: '#FFFFFF',
        type: String,
      },
      badge_color:
      {
        default: '#FFFFFF',
        type: String,
      },
      text_bg_color:
      {
        default: '#FFFFFF',
        type: String,
      },
      text_color:
      {
        default: '#FFFFFF',
        type: String,
      }
    },
    data: {
      coach: {
        imageUrl: 'img/demo.png',
        name: 'Demo coach',
        email: 'demo@thriving-now.com'
      }
    },
  }

</script>
<style scoped>
  .card-content {
    min-height: 100px
  }
  img{
    width:75px;
    height:75px;
  }

  @media (max-width: 1280px) {
    p {
      font-size: 8px;
    }

    img{
        width:50px;
        height:50px;
    }
  }
</style>
