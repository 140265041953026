export default [
  {
    name: 'Forside',
    icon: 'nc-icon nc-bank',
    path: '/overview'
  },
  {
    name: 'Forside',
    icon: 'nc-icon nc-bank',
    path: '/overview'
  }, {
    name: 'Forside',
    icon: 'nc-icon nc-bank',
    path: '/overview'
  }, {
    name: 'Forside',
    icon: 'nc-icon nc-bank',
    path: '/overview'
  }, {
    name: 'Forside',
    icon: 'nc-icon nc-bank',
    path: '/overview'
  }, {
    name: 'Forside',
    icon: 'nc-icon nc-bank',
    path: '/overview'
  }, {
    name: 'Forside',
    icon: 'nc-icon nc-bank',
    path: '/overview'
  }, {
    name: 'Forside',
    icon: 'nc-icon nc-bank',
    path: '/overview'
  }, {
    name: 'Forside',
    icon: 'nc-icon nc-bank',
    path: '/overview'
  },
]
