<template>
  <div class="content">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6 d-flex align-items-stretch" v-for="stats in statsCards">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="stats.title"
                    :badge_bg_color="stats.badge_bg_color"
                    :badge_color="stats.badge_color"
                    :text_bg_color="stats.text_bg_color"
                    :text_color="stats.text_color"
                    :image="stats.image"
                    :description="stats.description">
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card" :style="{'background-color': exercise.backgroundColor}">
          <div class="card-header">
            <h3><span>Session 7 </span>- Find dit talentsprog</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <div class="card" style="background-color: #E1B4BB">
                  <div class="card-body text-center">
                    <div class="embed-responsive embed-responsive-16by9">
                      <video controls>
                        <source v-if="exercise.videoUrl" :src="exercise.videoUrl" type="video/mp4">
                      </video>
                    </div>
                  </div>
                </div>
                <div class="card" style="background-color: #E1B4BB">
                  <div class="card-body">
                    <h5>DIN FORBEREDELSE</h5>
                    <p style="font-size:small">Find nogle rolige omgivelser, hvor du kan sidde uforstyrret og arbejde med øvelsen. </p>
                    <h5>SÅDAN GØR DU</h5>
                    <p style="font-size:small">
                      Noter dig dine svar på nedenstående spørgsmål i skrivefeltet til højre og gem derefter. Øvelsen er forberedelse til næste session
                    </p>
                    <ol>
                      <li style="font-size:smaller"><span style="font-weight:bold">Følelsen:</span> Gå tilbage i tid og find 3 tidspunkter i dit arbejdsliv, hvor du følte dig som en fisk i vandet. Du skal tilbage til tidspunkter, hvor du følte dig energifyldt, motiveret og dybt engageret – til tidspunkter, hvor du følte, at du brugte dig selv på den helt rigtige måde. Det kan være du skal langt tilbage i tid, det kan være, at du kan finde sådanne tidspunkter i sidste uge – det ved kun du.</li>
                      <li style="font-size:smaller"><span style="font-weight:bold">Situationen:</span> Beskriv kort den situation du var i, da du havde følelsen, du finder under punkt 1. Var du eksempelvis sammen med andre eller alene? Hvor var du henne, og hvilken virksomhed arbejdede du for? Hvilken opgave var du i gang med at løse?  </li>
                      <li style="font-size:smaller"><span style="font-weight:bold">Handlingen:</span>Hvad lavede du helt konkret i den pågældende situation? Sad du i en forhandling? Var du i gang med at formidle et budskab på skrift eller fra en talerstol? Var du i gang med at udarbejde en strategi eller en analyse? Eller lavede du noget helt andet? Vær meget konkret, når du beskriver dette punkt. </li>
                      <li style="font-size:smaller"><span style="font-weight:bold">Ressourcerne:</span> Hvilke ressourcer brugte du for at kunne udføre den pågældende handling, du fandt under punkt 3? Hvad er det du ejer/har/besidder/kan, som gjorde dig i stand til at gøre det, du konkret gjorde? Skriv en liste af alle de ting, du mener, der var afgørende for, at du kunne udføre opgaven. Der er formentlig mange ting, som du trak på helt ubevidst. Når du ikke kan komme på mere, skal du sætte ring om de tre ressourcer/kompetencer, som du mener har været allermest afgørende for, at du kunne udføre opgaven.</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="col-8">
                <div class="row">
                  <div class="card" style="background-color:white; width:98%; height:100%">
                    <vue-editor v-model="content"></vue-editor>                    
                  </div>
                </div>
                <div class="row">
                  <div class="col-8 text-center">
                    <p-button type="info" @click="SaveDraft">
                      <i slot="label" class="nc-icon nc-paper"></i>
                      Gem
                    </p-button>
                    </div>
                  <div class="col-4">
                    <p-button type="success" @click="SendToCoach">
                      <i slot="label" class="nc-icon nc-send"></i>
                      Send til Coach
                    </p-button>
                    <p-button type="success">
                      <i slot="label" class="nc-icon nc-email-85"></i>
                      <a href="mailto:jill@thriving-now.com" style="color:white;">Send en Email</a>
                    </p-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
  import Communication from 'src/communication/Communication.vue';

  import { VueEditor } from "vue2-editor";
  export default {

    components: {
      StatsCard,
      VueEditor,
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    methods: {
      async SaveDraft() {
        // `this` inside methods points to the current active instance
        console.log(this.content);
        var result = await Communication.SendExcersizeData(this.content, 1, 1);
      },
      async SendToCoach() {
        console.log(this.content);
        var result = await Communication.SendExcersizeData(this.content, 2, 1);
      },
    },
    data() {
      return {        
        statsCards: [
          {
            type: 'info',
            icon: 'nc-icon nc-favourite-28',
            image: 'img/badges/badge-alarm.png',
            title: '112 Knap',
            description: 'Hvis du er kørt fast og har brug for hjælp',
            badge_bg_color: 'white',
            bagde_color: 'red',
            text_bg_color: 'red',
            text_color: 'white'
          },
          {
            type: 'warning',
            icon: 'nc-icon nc-globe',
            title: '"Thriving Alumni"',
            image: 'img/badges/badge-alumni.png',
            description: 'Med din deltagelse er du en del af den fremtidige rejse sammen med de mange andre i Alumni Thriving',
            badge_bg_color: '#2A3443',
            bagde_color: '',
            text_bg_color: '#',
            text_color: '#'
          },
          {
            type: 'success',
            icon: 'nc-icon nc-money-coins',
            title: '"I want to Thrive"',
            image: './img/badges/badge-iwant.png',
            description: 'Tillykke med at du er i gang med et forløb om at få et arbejdsliv i Stortrivsel',
            badge_bg_color: '#2A3443',
            bagde_color: '',
            text_bg_color: '#',
            text_color: '#'

          },
          {
            type: 'danger',
            icon: 'nc-icon nc-vector',
            title: '"Pay it forward now"',
            image: 'img/badges/badge-trophy.png',
            description: 'Tak - en del af din betaling er resereveret til en samlet pulje der kommer alle til gode',
            badge_bg_color: '#2A3443',
            bagde_color: '',
            text_bg_color: '#',
            text_color: '#'

          }
        ],
        content: {}
      }
    },

    async mounted() {
      var result = await Communication.GetExerciseData(1);
      this.content = result.data;
    },
  }
</script>
<style>
</style>
