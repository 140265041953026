<template>
  <div class="register-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page ">
      <div class="full-page register-page section-image" filter-color="black">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 col-md-5 ml-auto">
                <info-section type="primary"
                              title="Indtast brugernavn eller email"
                              description=""
                              icon="nc-icon nc-check-2">

                </info-section>
                <info-section type="primary"
                              title="Check din email fra os"
                              description=""
                              icon="nc-icon nc-check-2">

                </info-section>
                <info-section type="primary"
                              title="Login med dit nye password"
                              description=""
                              icon="nc-icon nc-check-2">

                </info-section>
                <info-section type="primary"
                              title="Ændre dit password i profilen"
                              description=""
                              icon="nc-icon nc-check-2">

                </info-section>
              </div>
              <div class="col-lg-4 col-md-6 mr-auto">
                <form @submit.prevent="resetPassword">
                  <card type="signup" class="text-center">
                    <template slot="header">
                      <h4 class="card-title">Nulstil dit password</h4>
                    </template>

                    <fg-input required v-model="form.email" placeholder="Email eller brugernavn"></fg-input>

                    <p-button slot="footer" type="success" native-type="submit" round>Send nyt password</p-button>
                  </card>
                </form>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import { Card, Checkbox, Button, InfoSection } from 'src/components/UIComponents';
  import Communication from 'src/communication/Communication.vue';

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      InfoSection,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button
    },
    data() {
      return {
        form: {
          email: '',
        }
      }
    },
    methods: {
      async resetPassword() {
        var result = await Communication.resetPassword(this.form.email);

        if (result == true) {
          this.$router.push('/')
        }
      }
    },
    beforeDestroy() {

    }
  }
</script>
<style>
</style>
