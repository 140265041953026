<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright pull-right">
        &copy;  by <a href="https://www.thriving-now.com" target="_blank">Thriving</a>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>

</style>
