<template>
  <div class="wrapper">

    <side-bar type="sidebar">
      <user-menu></user-menu>
    </side-bar>

    <div class="main-panel">
      <top-navbar :IsAdmin="isAdmin"></top-navbar>

      <dashboard-content >

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import UserMenu from 'src/components/UIComponents/SidebarPlugin/UserMenu.vue'
  import Communication from 'src/communication/Communication.vue';


  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      UserMenu,
    },
    data() {
      return {
        isAdmin: false,
        }
    },
    methods: {

    },
    async mounted() {
      this.isAdmin = false;
      var result = await Communication.GetCommand("api/User/IsAdministrator")
      if (result == true)
        this.isAdmin = result;
    },
  }


</script>
