<template>
  <div class="register-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page ">
      <div class="full-page register-page section-image" filter-color="black">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 col-md-5 ml-auto">
                <info-section class="mt-5"
                              type="primary"
                              title="Bliv en del af Thriving universet"
                              description=""
                              icon="nc-icon nc-trophy">

                </info-section>                
              </div>
              <div class="col-lg-4 col-md-6 mr-auto">
                <form @submit.prevent="createUser">
                  <card type="signup" class="text-center">
                    <template slot="header">
                      <h4 class="card-title">Registrer dig</h4>
                    </template>

                    <fg-input required v-model="form.firstName" placeholder="Fornavn..."></fg-input>
                    <fg-input required v-model="form.lastName" placeholder="Efternavn..."></fg-input>
                    <fg-input required v-model="form.username" placeholder="Brugernavn..."></fg-input>
                    <fg-input required v-model="form.password" type="password" placeholder="Password..."></fg-input>
                    <fg-input required v-model="form.email" placeholder="Email..."></fg-input>
                    <fg-input required v-model="form.telephone" placeholder="Telefon..."></fg-input>
                    <p-checkbox class="text-left" v-model="form.acceptTerms" required>
                      Jeg accepterer
                      <a href="https://www.thriving-universe.com/documents/TermsAndConditions.pdf"  target=”_blank”>Thrivings betingelser</a>.
                    </p-checkbox>

                    
                  </card>
                  </form>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import { Card, Checkbox, Button, InfoSection } from 'src/components/UIComponents';
  import Communication from 'src/communication/Communication.vue';

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      InfoSection,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button
    },
    data(){
      return {
        form: {
          firstName: '',
          lastName: '',
          username: '',
          email: '',
          telephone:'',
          acceptTerms: true
        }
      }
    },
    methods: {
      async createUser() {
        console.log(this.form);
        var login = await Communication.createUser(this.form);
        
        if (login == true) {
          this.$router.push('/')
        }
        else {
          // Error message
        }
          

      }
    },
    beforeDestroy() {

    }
  }
</script>
<style>
</style>
