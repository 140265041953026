<script>

  import axios from 'axios'

  let HttpCommon = axios.create({
    //baseURL: 'https://localhost:7061/',
    baseURL: 'https://backend.thriving-universe.com/',   // Production
    timeout: 10000,
    headers: {
      common: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.accessToken
      }
    }
  });

  export default {
    async GetCommand(apiUrl) {
      if (HttpCommon.defaults.headers.common['Authorization'] == "Bearer undefined") {
        HttpCommon.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.accessToken;
      }

      var result = await HttpCommon.get(apiUrl);
      if (result.status == 401) {
        console.log("Retrying request")
        result = await HttpCommon.get(apiUrl);
      }
      return result.data;
    },

    async GetCommandRaw(apiUrl) {

      var result = await HttpCommon.get(apiUrl);
      return result;
    },
    async PostCommand(apiUrl, payload) {
      if (HttpCommon.defaults.headers.common['Authorization'] == "Bearer undefined") {
        HttpCommon.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.accessToken;
      }

      var result = await HttpCommon.post(apiUrl, payload);
      return result.data;
    },

    async login(username, password) {
      let payload = { UserName: username, Password: password };
      var result = await HttpCommon.post('api/Authentication/SignIn', payload);

      if (result.data == "")
        return false;

      sessionStorage.accessToken = result.data.accessToken;
      sessionStorage.refreshToken = result.data.refreshToken;

      return true;
    },
    async resetPassword(email) {
      var result = await HttpCommon.get('api/User/ResetPassword?email=' + email);
      return result.data;
    },
    async createUser(object) {
      let payload = { FirstName: object.firstName, LastName: object.lastName, UserName: object.username, Email: object.email, Phone: object.telephone, Password: object.password };
      var result = await HttpCommon.post('api/User/CreateUser', payload);

      if (result != null) {
        var loginSuccess = await this.login(object.username, object.password);

        return loginSuccess;
      }
      else {
        return false;
      }
    },
    async GetUsersTalents() {
      var result = await HttpCommon.get('api/user/Talents?userId=' + localStorage.User);
      return result.data;
    },
    async GetUserInformation() {
      if (sessionStorage.accessToken == undefined) {
        return null;
      }

      var result = await HttpCommon.get('api/user/GetUserInformation', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.accessToken } });
      //await HttpCommon.get('api/user/GetUserInformation', { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.accessToken } })
      //  .then(response => { result = response.data });

      return result.data;
    },
    async SendExcersizeData(data, state, moduleNumber, sessionNumber) {
      let payload = { Data: data, State: state, ModuleNumber: moduleNumber, SessionNumber : sessionNumber};
      var result = await HttpCommon.post('api/user/SendExerciseData', payload);

      return result.data;
    },
    async GetExerciseData(exerciseNumber) {
      if (HttpCommon.defaults.headers.common['Authorization'] == "Bearer undefined") {
        HttpCommon.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.accessToken;
      }

      var result = await HttpCommon.post('api/user/GetExerciseData', exerciseNumber)
      return result.data;
    },
    async SignOut() {
      if (HttpCommon.defaults.headers.common['Authorization'] == "Bearer undefined") {
        HttpCommon.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.accessToken;
      }

      let payload = { accessToken: sessionStorage.accessToken };
      var result = await HttpCommon.post('api/Authentication/SignOut?accessToken=' + sessionStorage.accessToken)
      return result.data;
    },
  }
</script>
