<template>
  <div class="sidebar"
       :data-color="backgroundColor">
    <div class="logo">
      <a href="/">
        <img :src="logo" alt="Thriving universe">
      </a>
    </div>

    <div class="sidebar-wrapper">
      <slot>

      </slot>
      <ul class="nav">
        <slot name="links">

          <h4 style="padding-left:10px">Dine top talenter</h4>
          <div v-if="aboveLimit.length == 0">
            <p style="margin-left:10px">Talenter er ikke tilgængelige</p>
          </div>
          <div v-for="talent of aboveLimit">
            <div v-if="talent.type==1" class="DivTalent">
              <p class="PTalent Type1" :title="talent.description">{{talent.name}} ({{talent.score.toFixed(2)}})</p>
            </div>
            <div v-if="talent.type==2" class="DivTalent">
              <p class="PTalent Type2" :title="talent.description">{{talent.name}} ({{talent.score.toFixed(2)}})</p>
            </div>
            <div v-if="talent.type==3" class="DivTalent">
              <p class="PTalent Type3" :title="talent.description">{{talent.name}} ({{talent.score.toFixed(2)}})</p>
            </div>
            <div v-if="talent.type==4" class="DivTalent">
              <p class="PTalent Type4" :title="talent.description">{{talent.name}} ({{talent.score.toFixed(2)}})</p>
            </div>
          </div>
          <hr />
          <h4 style="padding-left:10px">Dine non-talenter</h4>
          <div v-if="belowLimit.length == 0">
            <p style="margin-left:10px">Talenter er ikke tilgængelige</p>
          </div>

          <div v-for="talent of belowLimit">
            <div v-if="talent.type==1" class="DivTalent">
              <p class="PTalent Type1" :title="talent.description">{{talent.name}} ({{talent.score.toFixed(2)}})</p>
            </div>
            <div v-if="talent.type==2" class="DivTalent">
              <p class="PTalent Type2" :title="talent.description">{{talent.name}} ({{talent.score.toFixed(2)}})</p>
            </div>
            <div v-if="talent.type==3" class="DivTalent">
              <p class="PTalent Type3" :title="talent.description">{{talent.name}} ({{talent.score.toFixed(2)}})</p>
            </div>
            <div v-if="talent.type==4" class="DivTalent">
              <p class="PTalent Type4" :title="talent.description">{{talent.name}} ({{talent.score.toFixed(2)}})</p>
            </div>
          </div>

        </slot>

      </ul>
      <div class="row">
        <div class="col-12">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-4 text-center">
          <div style="margin-left:20px;">
            <img :src="coach.imageUrl" alt="coach.name">
          </div>
        </div>
        <div class="col-8 align-middle">
          <p style="font-weight:bold">
            Coach<br />
            {{coach.name}}<br />
            <a :href="`mailto:${coach.email}`">Send email</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import 'perfect-scrollbar/css/perfect-scrollbar.css'
  import Communication from 'src/communication/Communication.vue';


  export default {
    props: {
      title: {
        type: String,
        default: 'Thriving',
        description: 'Sidebar title'
      },
      backgroundColor: {
        type: String,
        default: 'white',
        validator: (value) => {
          let acceptedValues = ['white', 'brown', 'black']
          return acceptedValues.indexOf(value) !== -1
        },
        description: 'Sidebar background color (white|brown|black)'
      },
      logo: {
        type: String,
        default: 'img/thriving_mail.png',
        description: 'Sidebar Logo'
      },
    },
    methods: {

    },
    async created() {

      if (sessionStorage.accessToken == 'undefined') {
        this.$router.push('/Login')
      }

      await Communication.GetUserInformation().
        then(response => {
          if (response == null) {
            this.$router.push('/Login')
            return;
          }
          this.sidebarLinks = response.talents;
          this.coach = response.contactPersons[0];
          this.recieveRespons = response;
        })
        .catch(error => {          
          this.$router.push('/Login')
        }
          )
    },
    data() {
      return {
        sidebarLinks: [],
        recieveRespons: {},
        coach: {
          imageUrl: 'img/demo.png',
          name: 'Demo coach',
          email: 'demo@thriving-now.com'
          }
        }
    },
    computed: {
      aboveLimit() {
        return this.sidebarLinks.filter(n => n.score >= 6.5)
      },
      belowLimit() {
        return this.sidebarLinks.filter(n => n.score < 4.5)
      }
    },
    beforeDestroy() {
    }
  }

</script>
<style>
  .DivTalent {
    padding-left: 5px;
    padding-top:3px;
    padding-right:10px;
    padding-bottom:3px;
  }

  .PTalent {
    /*border:solid 1px;*/
    border-radius: 8px;
    padding-left: 5px;
    font-weight: lighter;
  }

  .Type1 {
      /* Tænkende */
    background-color: #D3E5EF;
  }

  .Type2 {
      /* Stræbende */
    background-color: #E8DEEE;
  }

  .Type3 {
    /* Relaterende */
    background-color: #FADEC9;
  }

  .Type4 {
    /* Påvirkende */
    
    background-color: #CDDFCE;
  }

  @media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
      display: none;
    }
  }

  @media(max-width: 1280px)
  {
    .DivTalent {
      padding-left: 5px;
      padding-top: 3px;
      padding-right: 10px;
      padding-bottom: 3px;
    }

    .PTalent {
      /*border:solid 1px;*/
      border-radius: 8px;
      padding-left: 5px;
      font-weight: lighter;
      line-height:20px !important;
    }   
  }
  .customPhoto {
    border-radius: 50%;
  }
</style>
